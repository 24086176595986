<template>
  <div>
    <template v-if="model.type === 'input'">
      <van-field
      :type="getInputType(model.options.dataType)"
      v-model="dataValue"
      :name="model.name"
      :label="model.name"
      :placeholder="getPlaceholder(model.options.placeholder, model.type)"
      :rules="model.rules"
      :readonly="disabled || model.options.disabled"
    />
    </template>
    <template v-else-if="model.type === 'textarea'">
      <van-field
        v-model="dataValue"
        rows="3"
        autosize
        :name="model.name"
        :label="model.name"
        type="textarea"
        :readonly="disabled || model.options.disabled"
        :rules="model.rules"
        :placeholder="getPlaceholder(model.options.placeholder, model.type)"
      />
    </template>
    <template v-else-if="model.type === 'select'">
      <van-field
        clickable
        :name="model.name"
        :value="getSelectMsg(dataValue)"
        readonly
        :label="model.name"
        :rules="model.rules"
        :placeholder="getPlaceholder(model.options.placeholder, model.type)"
        @click="showPicker = model.options.disabled ? false : !disabled"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          v-if="!model.options.multiple"
          show-toolbar
          :columns="model.options.options"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
        <multiple-select v-else :value="dataValue" :dataList="model.options.options" @change="(val) => dataValue = val" @close="showPicker = false"/>
      </van-popup>
    </template>
    <template v-else-if="model.type === 'date'">
    <van-field
      clickable
      readonly
      :name="model.name"
      :value="dataValue"
      :rules="model.rules"
      :label="model.name"
      :placeholder="getPlaceholder(model.options.placeholder, model.type)"
      @click="showPicker = model.options.disabled ? false : !disabled"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="dataNow"
        :type="getDateType(model.options.format)"
        @confirm="onConfirm"
        :formatter="formatter"
        @cancel="showPicker = false"
      />
    </van-popup>
    </template>
  </div>
</template>

<script>
import {formatterDate, formatterDateTime, getDefaultDateTime} from '@/utils/utils'
import multipleSelect from '../select/multipleSelect'
import {newDate} from "../../utils/utils";
export default {
  props: ['datas', 'disabled', 'value', 'id'],
  components: {multipleSelect},
  data () {
    return {
      model: {},
      dataValue: null,
      showPicker: false,
      pikerValue: null,
      dateType: null,
      dataNow: null
    }
  },
  watch: {
    datas: {
      handler (val) {
        this.setData(val)
      }
    },
    value: {
      deep: true,
      handler (val) {
        if (Array.isArray(val)) {
          this.dataValue = []
          this.model.options.options.forEach(item => {
            if (val.some(v => item.value == v)) {
              item['check'] = true
              this.dataValue.push(item)
            }
          })
        } else if (this.model.type == 'select') {
          this.setSelectValue(val)
        } else if (this.model.type === 'date' && this.model.options.isDefaultNow) {
          if (!this.id) {
            this.dataTime = new Date(getDefaultDateTime(this.model.options.format))
            let defaultNow = getDefaultDateTime(this.model.options.format)
            this.dataValue = defaultNow
          } else {
            this.dataTime = new Date(val)
            this.dataValue = val
          }
        } else {
          this.dataValue = val == 'null' ? '' : val
        }
      }
    }
  },
  methods: {
    getInputType (type) {
      if (type == 'number' || type == 'integer' || type == 'float') {
        return 'number'
      } else {
        return type
      }
    },
    getDateType (type) {
      this.dateType = type
      switch (type) {
        case 'yyyy-MM-dd' :
        return 'date'
        case 'yyyy-MM-dd HH:mm:ss' :
        return 'time'
      }
    },
    getPlaceholder (val, type) {
      if(val) {
        return val
      }
      if(type == 'input' || type == 'textarea') {
        return '请输入'
      }
        return '请选择'
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    setSelectValue (val) {
      this.dataValue = this.model.options.options.find(item => item.value == val) ? this.model.options.options.find(item => item.value == val).label : ''
      this.pikerValue = this.model.options.options.find(item => item.value == val) ? this.model.options.options.find(item => item.value == val).value : ''
    },
    setData (val) {
      if (val.type === 'select') {
        val.options.options.forEach(element => {
          if (!element.label) {
            element['text'] = element.value
            element['label'] = element.value
          } else {
            element['text'] = element.label
          }
        });
        if (this.dataValue == null && this.dataValue != this.value) {
          this.setSelectValue(this.value)
        }
      } else if (val.type === 'date' && val.options.isDefaultNow) {
        if (!this.id) {
          this.dataTime = new Date(getDefaultDateTime(val.options.format))
          let defaultNow = getDefaultDateTime(val.options.format)
          this.dataValue = defaultNow
        } else {
          this.dataTime = new Date(this.value)
          this.dataValue = this.value
        }
      } else {
        if (this.dataValue == null && this.dataValue != this.value) {
          this.dataValue = this.value
        }
      }
      this.model = val

    },
    getSelectMsg (val) {
      if (Array.isArray(val)) {
        let arr = []
        val.map(item => {
          if (typeof item == 'string' ) {
            arr.push(this.model.options.options[this.model.options.options.findIndex(val => val.value == item)].label)
          } else {
            arr.push(this.model.options.options[this.model.options.options.findIndex(val => val.value == item.value)].label)
          }
        })
        return arr.join(',')
      } else {
        return val
      }
    },
    onConfirm (val) {
      if (val.text) {
        this.dataValue = val.text
        this.pikerValue = val.value
      } else if (this.dateType) {
        this.dataValue = this.dateType === 'yyyy-MM-dd' ? formatterDate(val) : formatterDateTime(val)
      } else {
        this.dataValue = val
      }
      this.showPicker = false
    },
    getValue () {
      let value = this.dataValue
      if (this.model.type == 'select' && !this.model.options.multiple) {
        value = this.pikerValue
      }
      return {key: this.model.model, value: value}
    }
  },
  created () {
    this.dataNow = new Date()
    this.setData(this.datas)
  }
}
</script>

<style scoped>
.van-field >>> .van-field__control {
  line-height: 46px;
}
</style>
