<template>
<div>
    <div class="typeCheck">
      <span @click="showChange"></span>
      <span>请选择</span>
      <span @click="showChange">确定</span>
    </div>
    <van-checkbox-group class="typeCheckBox" @change="change" v-model="value">
      <van-cell-group v-for="(item, index) in dataList" :key="index">
        <van-cell  :title="item.label"  @click="toggle(index)">
          <template #icon>
            <van-checkbox :name="item.value" ref="checkboxes" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-checkbox-group>
</div>
</template>

<script>
export default {
  props: [ 'dataList'],
  data () {
    return {
      value: [],
      typeStr: ''
    }
  },
  mounted () {
    this.value = this.dataList.filter(item => item.check).map(item => item.value)
  },
  methods: {
    showChange () {
      this.$emit('close')
    },
    change(val) {
      console.log(val);
      this.$emit('change', val)
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
  }
}
</script>

<style lang="scss" scoped>
.typeCheck {
  display: flex;
  height: 150px;
  line-height: 115px;
  padding-left: 40px;
  span {
    display: block;
    flex: 1;
    font-size: 30px;
  }
  span:nth-child(1) {
    font-size: 28px;
    color: #969799;
  }
  span:nth-child(3) {
    margin-right: -187px;
    font-size: 28px;
    color: #576b95;
  }
}
.typeCheckBox .van-cell__title {
  margin-left: 20px;
}
.typeCheckBox .van-icon {
  position: absolute;
  right: 40px;
  bottom: 45%;
}
.children {
  margin-left: 50px;
}
.children .van-cell__value {
  margin-left: 10px;
}
</style>
